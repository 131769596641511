<template>
	<Popup title="Edit Profile">
		<form class="flex flex-col gap-4">
			<FormGroup
					v-model:parent_ref="username"
					label="Username"
					:disabled="true"
					placeholder="Username"
			/>
			<FormGroup
					v-model:parent_ref="formData.email"
					label="Email"
					type="text"
					placeholder="Email"
					:check="(value) => {
                        return value.length <= fieldLimits.email.max && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
                    }"
			/>
			<button type="button" @click="changePassword = !changePassword"
			        class="change-password-button text-blue-700 flex gap-3 items-center px-3 py-2 rounded-lg w-fit justify-start border-2 border-blue-700
			               hover:bg-blue-700 hover:text-white-0">
				Change Password
				<img alt="Arrow pointing to the right" src="~/assets/icons/right_arrow_small.svg" width="10" height="10"
				     :style="{transform: changePassword ? 'rotate(90deg)' : 'rotate(0deg)'}">
			</button>
			<template v-if="changePassword">
				<FormGroup
						v-model:parent_ref="formData.current_password"
						label="Current Password"
						type="password"
						autocomplete="password"
						placeholder="Current Password"
				/>
				<FormGroup
						v-model:parent_ref="formData.password"
						label="New Password"
						type="password"
						autocomplete="new-password"
						placeholder="New Password"
						:check="(value) => {
                            return value.length >= fieldLimits.password.min && value.length <= fieldLimits.password.max && value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/);
                        }"
				/>
				<FormGroup
						v-model:parent_ref="formData.confirm_password"
						label="New Password (Confirm)"
						type="password"
						placeholder="New Password (Confirm)"
						autocomplete="new-password"
						:check="(value) => {
                            return value && value === formData.password;
                        }"
				/>
			</template>
			<PopupsComponentsPopupButton
					text="Edit"
					:action="edit"
					:arrow="null"
					/>
		</form>
	</Popup>
</template>

<script setup>
import { usePopup } from "@/composables/usePopup";
import { notify } from "@kyvg/vue3-notification";

const { setPopup } = usePopup();

const changePassword = ref(false);

const user = useUser();

const username = user.value.username;

const formData = reactive({
	email: user.value.email,
	current_password: "",
	password: "",
	confirm_password: "",
});

// edit

const edit = async () => {
	let data = {
		email: formData.email !== user.value.email ? formData.email : undefined
	};

	if (
		formData.password ||
		formData.confirm_password ||
		formData.current_password
	) {
		if (formData.password !== formData.confirm_password) {
			notify({
				type: "error",
				title: "Passwords do not match"
			})
			return;
		}

		data = {
			...data,
			current_password: formData.current_password,
			new_password: formData.password,
		};
	}

	const response = await $fetch("/api/user/edit", {
		method: "POST",
		body: data,
		ignoreResponseError: true
	});

	if (response.status) {
		if (response.info) {
			notify({
				type: "info",
				title: response.info
			})
		} else {
			if (formData.password || formData.confirm_password || formData.current_password) {
				notify({
					type: "info",
					title: "Password has been changed"

				})
			} else {
				notify({
					type: "info",
					title: "Password has not been changed",
				})
			}

			notify({
				type: "success",
				title: "Your profile has been edited successfully"
			})
		}
		setPopup(null);

		const session = useCookie("session");
		const userData = getUserData(session.value);
		if(userData) {
			user.value = userData;
		}
		setUser(userData);
	} else if (response.error) {
		notify({
			type: "error",
			title: response.error
		})
	} else {
		notify({
			type: "error",
			title: "An error occurred while editing the your profile"
		})
	}
};
</script>

<style scoped lang="scss">
.change-password-button {
    img {
        filter: brightness(0) saturate(100%) invert(39%) sepia(62%) saturate(4584%) hue-rotate(211deg) brightness(90%) contrast(96%);
    }
}
</style>